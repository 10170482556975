import React, { useState, useEffect } from "react";
import styles from "./LoginPage.module.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { getAccount, getRequests } from "../api";

export const HomePage = (props) => {

    const location = useLocation();
    const { foundAccount } = location.state;
    const [account, setAccount] = useState({});
    const [requests, setRequests] = useState([]);

    const navigate = useNavigate();
    //const navigateToRequest = () => navigate('/requestleave');
    //const navigateToManage = () => navigate('/managerequests');
    //const navigateToAccount = () => navigate('/account');

    useEffect(() => {
      //console.log(requests)
        async function loadAccountData() {
          let accountData = await getAccount(foundAccount._id);
          if(accountData) {
            setAccount(accountData);
          }
        }

        loadAccountData();
        loadAllRequests();
      },[])

    async function loadAllRequests() {
      let requestData = await getRequests();
      if(requestData) {
        setRequests(requestData);
      }
    }

    const convertToCSV = (objArray) => {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
  
      for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
          if (line !== '') line += ',';
  
          line += array[i][index];
        }
        str += line + '\r\n';
      }
      return str;
    };
    
      const downloadCSV = () => {
        loadAllRequests();
        const csvData = new Blob([convertToCSV(requests)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `request-data.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    if(foundAccount.management){
      return(
        <div style={styles}>
            <h1>Logged in as Manager</h1>
            <Link to="/account" style={{ textDecoration: 'none', color:"white" }} state={{foundAccount:account}}><button> Manage Account </button></Link>
            <Link to="/managerequests" style={{ textDecoration: 'none', color:"white" }} state={{foundAccount:account}}><button> Manage Requests </button></Link>
            <Link to="/manageaccounts" style={{ textDecoration: 'none', color:"white" }} state={{foundAccount:account}}><button> Manage Employee Accounts </button></Link>
            <Link to="/calendar" style={{ textDecoration: 'none', color:"white" }}><button> Calendar </button></Link>
            <Link to="/login" style={{ textDecoration: 'none', color:"white" }}><button> Logout </button></Link>
            <button onClick={downloadCSV}> Download Data </button>
        </div>
      );
    }
    else
    {
      return(
        <div style={styles}>
          <h1>Logged in as Employee</h1>
            <Link to="/account" style={{ textDecoration: 'none', color:"white" }} state={{foundAccount:account}}><button> Manage Account </button></Link>
            <Link to="/requestleave" style={{ textDecoration: 'none', color:"white" }} state={{foundAccount:account}}><button> Create PTO Request </button></Link>
            <Link to="/calendar" style={{ textDecoration: 'none', color:"white" }}><button> Calendar </button></Link>
            <Link to="/login" style={{ textDecoration: 'none', color:"white" }}><button> Logout </button></Link>
            <button onClick={downloadCSV}> Download Data </button>
        </div>
      );
    }
    
}