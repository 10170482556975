import React, { useState, useEffect } from "react";
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route, Navigate } from "react-router-dom"
import Root, { ROUTES } from "./components/root/Root";
import { RequestLeavePage } from "./containers/RequestLeavePage";
import { LoginPage } from "./containers/LoginPage";
import ManageRequestsPage from "./containers/ManageRequestsPage";
import { getAccounts, getRequests } from "./api";
import AccountForm from "./components/accountForm/AccountForm";
import { HomePage } from "./containers/HomePage";
import EventCalendar from "./components/eventCalendar/EventCalendar";
import ManageAccountsPage from "./containers/ManageAccountsPage";
import { set } from "date-fns";

export const App = () => {

  const [requests, setRequests] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  useEffect(() => {

    fetch("/accounts").then(
      res => res.json()
    ).then(
      data => {
        setData(data);
        let accountObject = [];
        for (let x = 0; x < data.length; x++){
          
          accountObject.push(
              {
                _id: data[x][0],
                username: data[x][1],
                management: data[x][2],
                firstName: data[x][3],
                lastName: data[x][4],
                email: data[x][5],
                password: data[x][6],
                region: data[x][7],
                daysOff: data[x][8]
              } 
          );
        }

        setAccounts([...accountObject]);
        console.log(accounts)
      }
    )

    fetch("/requests").then(
      res => res.json()
    ).then(
      data2 => {
        setData2(data2);
        let requestObject = [];
        for (let x = 0; x < data2.length; x++){
          
          requestObject.push(
              {
                _id: data2[x][0],
                email: data2[x][1],
                accountid: data2[x][2],
                name: data2[x][3],
                startDate: data2[x][4],
                endDate: data2[x][5],
                approved: data2[x][6],
                note: data2[x][7]
              } 
          );
        }

        setRequests([...requestObject]);
        console.log(requests)
      }
    )

  },[])

  /*useEffect(() => {
    async function loadAllRequests() {
      let requestData = await getRequests();
      if(requestData) {
        setRequests(requestData);
      }
    }

    loadAllRequests();
  }, [requests])

  useEffect(() => {
    async function loadAllAccounts() {
      let accountData = await getAccounts();
      if(accountData) {
        setAccounts(accountData);
      }
    }

    loadAllAccounts();

  },[accounts])
  */

  const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/">
      <Route index element={ <Navigate to={ROUTES.LOGIN} replace/> }/>
      <Route path={ROUTES.LOGIN} element={ <LoginPage accounts={accounts}/>}/>
      <Route path={ROUTES.HOME} element={<HomePage requests={requests}/>}/>
      <Route path={ROUTES.REQUESTLEAVE} element={ <RequestLeavePage requests={requests} accounts={accounts}/>}/>
      <Route path={ROUTES.MANAGEREQUESTS} element={ <ManageRequestsPage requests={requests} accounts={accounts}/>}/>
      <Route path={ROUTES.MANAGEACCOUNTS} element={ <ManageAccountsPage requests={requests} accounts={accounts}/>}/>
      <Route path={ROUTES.ACCOUNT} element={<AccountForm accounts={accounts}/>}/>
      <Route path={ROUTES.CALENDAR} element={<EventCalendar requests={requests}/>}/>
    </Route>
  ));
  
  return (
    <RouterProvider router={router}/>
  );
};

