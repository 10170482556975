import React, { useState, useEffect }  from "react";
import emailjs from 'emailjs-com';
import { useLocation, useNavigate } from "react-router-dom";
import { createAccount, updateAccount } from "../../api";

function AccountForm(props){
  const getTodayString = () => {
    const [month, day, year] = new Date()
      .toLocaleDateString()
      .split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };
  
  const { accounts, proxycreated } = props;
  const location = useLocation();
  const { foundAccount } = location.state;
  const [username,setUsername] = useState("");
  const [management,setManagement] = useState("");
  const [firstName,setFirstName] = useState("");
  const [lastName,setLastName] = useState("");
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [passwordConfirm,setPasswordConfirm] = useState("");
  const [region,setRegion] = useState("");
  const [daysOff,setDaysOff] = useState(0);
  const [proxyCreated, setProxyCreated] = useState(proxycreated);
  //const [accountRequests,setAccountRequests] = useState({});
  //const [validated,setValidated] = useState(false);
  const [newAccount,setNewAccount] = useState(true);

  const navigate = useNavigate();
  const navigateToHome = () => navigate('/home',{state:{foundAccount}});
  const navigateToLogIn = () => navigate('/login');

  useEffect(() => {
    //Retrieve current account details
    //setEmail(foundAccount.email);
    if(foundAccount && !proxyCreated){
      setUsername(foundAccount.username);
      setManagement(foundAccount.management);
      setFirstName(foundAccount.firstName);
      setLastName(foundAccount.lastName);
      setEmail(foundAccount.email);
      setRegion(foundAccount.region);
      setDaysOff(foundAccount.daysOff);
      setNewAccount(false);
    }
    else
    {
      setManagement(false);
      setNewAccount(true);
      setDaysOff(14);
    }
  },[]);
  //useEffect(() => { console.log(validated) }, [validated])

  const validateAccountUpdate = async () => {
    const sameEmail = accounts.find((account) => account.email === email);
    if((username === "")||(firstName === "")||(email === "")||(password === "")||(region === "")){
      alert("Please fill in all empty fields.");
    }
    else if(sameEmail && newAccount){
      alert("Email already in use.");
    }
    else if(password !== passwordConfirm){
      alert("Entered new passwords don't match.");
    }
    else
    {
        let accountObject = {
          username: username,
          management: management,
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          region: region,
          daysOff: daysOff
        }
        if(!proxyCreated){
          if(newAccount){
            let data = await createAccount(accountObject);
            console.log(data)
            //console.log(createAccount(accountObject));
            alert(`Account Created`);
            navigateToLogIn();
          }
          else{
            updateAccount(foundAccount._id,accountObject);
            alert(`Account Information Updated`);
            navigateToHome();          
          }
        }
      else{
        createAccount(accountObject);
        const templateEmail = {
          emailName: foundAccount.firstName,
          employeeEmail: email,
          emailPassword: password
        };
        emailjs.send('service_cdwvpqe','template_dzerxkc', templateEmail, '5IQFPgP9Nk8yTA2hi')
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
        }, (err) => {
          console.log('FAILED...', err);
        });

        alert(`Account Created`);
      }
    }
  };

  const renderAccountButton = () => {
    if (newAccount) {
      return <button type="submit">Create Account</button>;
    } else {
      return <button type="submit">Update Account</button>;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    validateAccountUpdate();
  };

  return(
    <form onSubmit={handleSubmit} style={{margin:"auto"}}>
        <label>Account</label>
        <input 
            id="username"
            name="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
        />
        <input 
            id="firstName"
            name="firstName"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First Name"
        />
        <input
            id="lastName"
            name="lastName"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last Name"
        />
        <input 
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@email.com"
        />
        <input 
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
        />
        <input 
            id="passwordConfirm"
            name="passwordConfirm"
            type="password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            placeholder="Confirm Password"
        />
        <input 
            id="region"
            name="region"
            type="text"
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            placeholder="Region"
        />
        {renderAccountButton()}
    </form>

  );
}

export default AccountForm;