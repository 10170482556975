import axios from "axios";

const URL = "http://localhost:3000";
//const URL = process.env.REACT_APP_APP_HOST;

//Functions for Request Database

export async function postSickNote(file){
    console.log(file);
    const formData = new FormData();
    formData.append(
        "FILE",
        file,
        file.name
    );
    //formData.append('file', file);
    //formData.append('fileName', file.name);
    console.log(formData.get("FILE"));
    const response = await axios.post(`${URL}/uploadFile`, formData);
    console.log(response.data);
    return response.data;
}

export async function getRequests() {
    const response = await axios.get(`${URL}/requests`);
    if(response.status === 200){
        return response.data;
    }
    else{
        return;
    }
}

export async function createRequest(request) {
    const response = await axios.post(`${URL}/requests`, request);
    return response;
}

export async function updateRequest(id, request) {
    const response = await axios.put(`${URL}/requests/${id}`, request);
    return response;
}

export async function getRequest(id) {
    const response = await axios.get(`${URL}/requests/${id}`);
    console.log(response.data)
    if(response.status === 200){
        let requestResponse = {
            _id: response.data[0][0],
            email: response.data[0][1],
            accountid: response.data[0][2],
            name: response.data[0][3],
            startDate: response.data[0][4],
            endDate: response.data[0][5],
            approved: response.data[0][6],
            note: response.data[0][7]
        }
        //return response.data;
        return requestResponse
    }
    else{
        return;
    }
}

export async function deleteRequest(id){
    const response = await axios.delete(`${URL}/requests/${id}`);
    return response;
}

//Functions for Accounts Database

export async function getAccounts() {
    const response = await axios.get(`${URL}/accounts`);
    if(response.status === 200){
        return response.data;
    }
    else{
        return;
    }
}

export async function createAccount(account) {
    const response = await axios.post(`${URL}/accounts`, {account: account}, { headers: { 'Content-Type': 'application/json' } });
    return response;
}

export async function updateAccount(id, account) {
    const response = await axios.put(`${URL}/accounts/${id}`, account);
    return response;
}

export async function getAccount(id) {
    const response = await axios.get(`${URL}/accounts/${id}`);
    console.log(response.data)
    if(response.status === 200){
        let accountResponse = {
            _id: response.data[0][0],
            username: response.data[0][1],
            management: response.data[0][2],
            firstName: response.data[0][3],
            lastName: response.data[0][4],
            email: response.data[0][5],
            password: response.data[0][6],
            region: response.data[0][7],
            daysOff: response.data[0][8]
        }

        return accountResponse;
        //return response.data;
    }
    else{
        return;
    }
}

export async function deleteAccount(id){
    const response = await axios.delete(`${URL}/accounts/${id}`);
    return response;
}